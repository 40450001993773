<template>
  <div :class="[headerClassName, 'header-wrap']">
    
    <header id="header">
      <!-- logo @click="backFrontIndex"-->
      <div class="logo" >
        <router-link tag="h1" :to="{ name: 'frontIndex'}" class="img">
          <img v-if="type == '1'" src="@/assets/images/logo2.png" alt />
          <img v-else src="@/assets/images/logo.png" alt />
        </router-link>
      </div>
      <!-- end-logo -->
      <v-nav></v-nav>
      <search
        :showHeaderSearch="showHeaderSearch"
        @selectSearch="selectSearch"
        @clickSearch="clickSearch"
      ></search>
      <info></info>
    </header>
  </div>
</template>

<script>
import vNav from "./module/nav.vue";
import search from "./module/search.vue";
import info from "./module/info.vue";
export default {
  components: {
    vNav,
    search,
    info
  },
  props: {
    showHeaderSearch: {
      type: Boolean,
      default: false
    },
    headerClassName: {
      type: String,
      default: "header-opacity"
    }
  },
  data() {
    return {
      type:'1'
    };
  },
  mounted() {
    if(this.$route.path == "/"){
      this.type = '1';
    }else{
      this.type = '2';
    }
  },
  watch:{
    $route(to, from) {
      if(this.$route.path == "/"){
        this.type = '1';
      }else{
        this.type = '2';
      }
    }
  },
  methods: {
    backFrontIndex() {
      this.$store.commit("headerMenuId", null);
    },
    goLogin() {
      this.$router.push({
        path: "/login"
      });
    },
    //选中搜索选项
    selectSearch(val) {
      console.log(val);
    },
    //点击搜索全站
    clickSearch(val) {
      console.log(val);
    }
  }
};
</script>
<style lang="scss">
$white-opacity: hsla(0, 0%, 100%, 0.8);
.header-wrap {
  &.index {
    nav {
      margin: 0;
    }
    .list .item:hover a,
    .list .item:hover .show-more .iconfont,
    .header-user .message .btn-message:hover,
    .user .user-text .dot,
    .header-info .avatar-text a:hover {
      color: #fff;
    }
    .list .item a,
    .list .item .show-more .iconfont,
    .header-user .message .btn-message,
    .user .user-text .name span,
    .header-info .avatar-text a {
      color: $white-opacity;
    }
    .list .item:hover .pop-list li a {
      color: #333;
      &:hover {
        color: #0773fc;
      }
    }
  }
  &.index-fixed {
    nav {
      margin: 0;
    }
  }
}
</style>
<style rel="stylesheet/scss" lang="scss" scoped>
.header-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 1000;
  box-shadow: 0 4px 10px 0 rgba(48, 62, 83, 0.06);
  &.header-opacity {
    background: hsla(0, 0%, 100%, 0.96) !important;
  }
  &.index-fixed {
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: 0 30px 60px -10px rgba(48, 62, 83, 0.24);
    #header {
      background: hsla(0, 0%, 100%, 0.96);
    }
  }
  &.index {
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: unset;
    background: transparent;
    #header {
      background: transparent;
    }
    .pop-list {
      li {
        a {
          color: #333 !important;
        }
        &:hover {
          a {
            color: #0773fc !important;
          }
        }
      }
      li a {
        color: #333;
      }
    }
    #header {
      background: transparent;
      .logo {
        flex-shrink: 0;
      }
    }
  }
  #header nav .index-fixed {
    nav {
      margin: 0 auto;
    }
  }
  #header {
    margin: 0 auto;
    padding: 0 32px 0 8px;
    position: relative;
    display: flex;
    height: 70px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    transition: background-color 0.6s ease;
    .logo {
      position: relative;
      cursor: pointer;
      .img {
        display: inline-block;
        width: 180px;
        height: 44px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
